import type { FunctionComponent } from 'react';
import type { BentoThreeSquaresFragment } from '../../../graphql/queries/GetBentoThreeSquares.graphql';
import type { BentoPieceSquareCounterFragment, BentoPieceSquareFragment } from '../../../graphql/queries/GetBentoTwo.graphql';
import { BentoSquare } from './bento-square';
import { BentoSquareCounter } from './bento-square-counter';
const BentoPieceSquare: FunctionComponent<Props> = ({
  square
}) => {
  return <>
            {square?.type === 'BentoPieceSquare' && <BentoSquare layout={square?.layout} justifyContent={square?.justifyContent} headingMd={square?.headingMd} headingColor={square?.headingColor} subtitleMd={square?.subtitleMd} button={square?.button} backgroundType={square?.backgroundType} backgroundColor={square?.backgroundColor} backgroundImage={square?.backgroundImage} backgroundVideo={square?.backgroundVideo} image={square?.image} alignImage={square?.alignImage} copyImage={square?.copyImage} copyImageLocation={square?.copyImageLocation} isMarketplace={square?.isMarketplace} />}

            {square?.type === 'BentoPieceSquareCounter' && <BentoSquareCounter smallerVersion={square?.smallerVersion} counter={square?.counter} heading={square?.heading} subtitle={square?.subtitle} backgroundColor={square?.backgroundColor} button={square?.button} />}
        </>;
};
export default BentoPieceSquare;
type Props = Omit<BentoThreeSquaresFragment, 'sys' | 'type'> & {
  index?: number;
  square?: BentoPieceSquareFragment | BentoPieceSquareCounterFragment;
};